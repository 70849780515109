
// Modal 
.react-responsive-modal-modal {
  border-top: 2px solid var(--kt-primary);
  border-bottom: 2px solid var(--kt-primary);
  transition: ease-in-out 0.6s;
  background: var(--kt-card-bg) !important;
  border-radius: 5px;
  padding: 1.5rem !important;
  margin: 1.5rem !important;
}
.react-responsive-modal-modal:hover {
  border-top: 2px solid var(--kt-primary-active);
  border-bottom: 2px solid var(--kt-primary-active);
}
.react-responsive-modal-closeButton {
  fill: var(--kt-dark);
}
.modal-header {
  border-bottom: 1px solid var(--kt-input-border-color) !important;
}
.modal-footer {
  border-top: 1px solid var(--kt-input-border-color) !important;
}
