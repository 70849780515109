// smoodi-datatable layout changes

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--kt-menu-link-bg-color-active);
  color: var(--kt-white) !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0.3rem 0.7rem !important;
  margin: 0.25rem;
}
.dataTables_wrapper .dataTables_filter input {
  display: inline-block;
}
#data-table .table thead th {
  color: var(--info);
}
#data-table .dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
  color: var(--kt-gray-700) !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  border: none !important;
  background: var(--kt-secondary) !important;
}
.dataTables_wrapper .dataTables_filter input {
  color: var(--info);
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: var(--light) !important;
}
.table-responsive
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:hover {
  color: var(--kt-gray-700) !important ;
  border: none !important;
  background: var(--kt-menu-link-bg-color-active) !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background: var(--kt-menu-link-bg-color-active) !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  border: none !important;
}
.dataTables_filter {
  margin-bottom: 14px !important;
}
.buttons-html5 {
  display: none !important;
}
.buttons-print {
  display: none !important;
}
div.dataTables_processing > div:last-child > div:nth-child(1),
div.dataTables_processing > div:last-child > div:nth-child(2),
div.dataTables_processing > div:last-child > div:nth-child(3),
div.dataTables_processing > div:last-child > div:nth-child(4) {
  background: var(--kt-primary) !important;
}
table.dataTable.display > tbody > tr.odd > .sorting_1,
table.dataTable.order-column.stripe > tbody > tr.odd > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgb(0 0 0 / 2%) !important;
}
table.dataTable.display > tbody > tr:hover > * {
  box-shadow: inset 0 0 0 9999px rgb(0 0 0 / 2%) !important;
}
.daterangepicker .ranges li.active {
  background-color: var(--kt-primary);
}
.daterangepicker td.active:hover {
  background-color: var(--kt-primary) !important;
}
table.dataTable thead th,
table.dataTable tfoot th {
  color: var(--kt-gray-600) !important;
}
.dataTables_wrapper .dataTables_filter input {
  border: 1px solid var(--kt-input-border-color) !important;
}
.dataTables_filter label {
  color: var(--kt-gray-700) !important;
}
div.dataTables_wrapper div.dataTables_info {
  padding: 1.5rem 0 0 0 !important;
}
div.dataTables_wrapper div.dataTables_paginate {
  padding: 1rem 0 0 0 !important;
}
#data-table .table tr {
  border-color: var(--kt-input-border-color) !important;
  font-size: 1.15rem !important;
}
#data-table table.dataTable.no-footer {
  border-bottom: 1px solid var(--kt-input-border-color) !important;
}
#data-table .table > tbody {
  vertical-align: middle !important;
}
#data-table .table td {
  border-top: 1px solid var(--kt-input-border-color) !important;
}
#data-table .table thead {
  border-top: 1px solid rgba(0, 0, 0, 0.3) !important;
}
div.dataTables_wrapper div.dataTables_filter {
  padding: 0 !important;
}
td.details-control {
  background: url(https://www.datatables.net/examples/resources/details_open.png)
    no-repeat center right !important;
  cursor: pointer;
  transition: 0.5s;
}

tr.shown td.details-control {
  background: url(https://www.datatables.net/examples/resources/details_close.png)
    no-repeat center right !important;
  transition: 0.5s;
}

table.dataTable thead > tr > th.sorting {
  padding-right: 6px !important;
}

.sub-row {
  padding-left: 10px !important;
}
.sub-row td {
  padding-left: 10px !important;
}
.sub-row th {
  padding-left: 10px !important;
}

@media (max-width: 768px) {
  .dataTables_empty::before {
    display: none !important;
  }
}
@media (max-width: 600px) {
  .responsive-table thead {
    display: none;
  }

  .responsive-table,
  .responsive-table tbody,
  .responsive-table tr,
  .responsive-table td {
    display: block;
  }
  .responsive-table tr {
    margin-bottom: 15px;
  }
  .responsive-table td {
    padding-left: 50%;
    text-align: left;
    position: relative;
  }
  .responsive-table td:first-child {
    padding-left: 8px;
    border-top: 2px solid var(--kt-input-border-color) !important;
    font-weight: bold !important;
    background-color: transparent !important;
  }

  .responsive-table td[data-title] {
    display: flex;
    gap: 10px;
    align-items: center;
    align-content: center;
  }
  .responsive-table td::before {
    content: attr(data-title);
    width: 40%;
    display: block;
    font-weight: bold;
    text-align: left;
  }
  #ticketsTable td:last-child {
    border-bottom: 0;
    display: block !important;
  }
  #machineTable td:last-child {
    border-bottom: 0;
    display: flex !important;
    gap: 0 !important;
  }
}
@media (max-width: 768px) {
  .responsive-table thead {
    display: none;
  }
  .responsive-table,
  .responsive-table tbody,
  .responsive-table tr,
  .responsive-table td {
    display: block;
  }
  .responsive-table tr {
    margin-bottom: 15px;
  }
  .responsive-table td {
    padding-left: 50%;
    text-align: left;
    position: relative;
  }
  .responsive-table td:first-child {
    background-color: var(--kt-secondary) !important;
    padding-left: 8px;
    font-weight: bold !important;
    border-top: none !important;
  }
  #ticketsTable td:first-child {
    padding-left: 8px;
    border-top: 2px solid var(--kt-input-border-color) !important;
    font-weight: bold !important;
    background-color: transparent !important;
  }

  .responsive-table td[data-title] {
    display: flex;
    gap: 10px;
    align-items: center;
    align-content: center;
  }
  .responsive-table td::before {
    content: attr(data-title);
    width: 40%;
    display: block;
    font-weight: bold;
    text-align: left;
  }
  .responsive-table td:last-child {
    border-bottom: 0;
  }
}
@media (max-width: 768px) {
  .sub-row {
    padding-left: 5px !important;
  }
  .sub-row thead {
    display: none;
  }
  .sub-row td:first-child {
    padding-top: 17px;
  }
  .sub-row td:last-child {
    padding-bottom: 16px;
  }
  .sub-row tr {
    display: flex !important;
    flex-direction: column !important;
    padding: 1px;
  }
  .sub-row td[data-label] {
    display: flex !important;
    font-weight: 400;
  }
  .sub-row td[data-label]::before {
    content: attr(data-label);
    font-weight: bold;
    width: 50%;
  }
  .sub-row td {
    padding-left: 10px !important;
  }
  .sub-row td:nth-child(1) {
    background-color: var(--kt-secondary) !important;
    border-top: none !important;
    padding: 8px 15px !important;
  }
}

// Dashboard table card vew
@media (max-width: 425px) {
  .dashboard-table thead {
    display: none;
  }
  .dashboard-table,
  .dashboard-table tbody,
  .dashboard-table tr,
  .dashboard-table td {
    display: block;
  }
  .dashboard-table tr {
    margin-bottom: 15px;
  }
  .dashboard-table td {
    text-align: left;
    position: relative;
  }
  .dashboard-table td:first-child {
    background-color: var(--kt-light) !important;
    padding-left: 8px !important;
    font-weight: bold !important;
    border-top: none !important;
  }
  .dashboard-table td[data-title] {
    display: flex;
    gap: 10px;
    align-items: center;
    align-content: center;
  }
  .dashboard-table td::before {
    content: attr(data-title);
    width: 40%;
    display: block;
    font-weight: bold;
    text-align: left;
  }
  .dashboard-table tr td{
    border-bottom: 1px dashed var(--kt-table-border-color) !important;
  }
  .dashboard-table td:last-child {
    border-bottom: 0 !important;
  }
  .table tbody tr:last-child td {
    border-bottom: 1px dashed var(--kt-table-border-color) !important;
  }
  .table tbody tr:last-child td:last-child {
    border-bottom: 0 !important;
  }
  .table tbody tr{
    border-bottom: 0 !important;
  }
  .dashboardtable thead {
    display: none !important;
  }
}

// table loader
@keyframes table-spinner {
  to {
    transform: rotate(360deg);
  }
}
.table-spinner:before {
  content: "";
  display: table-caption;
  box-sizing: border-box;
  position: absolute;
  top: 60%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: #333;
  animation: table-spinner 0.6s linear infinite;
}

.dashboard .table.gy-4 td {
  padding-top: 0.5px !important;
  padding-bottom: 0.5px !important;
}

.dashboardtable tbody{
  display: block;
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.dashboardtable thead, .dashboardtable tbody tr{
  display: table;
  table-layout: fixed;
  width: 100%;
}

.btn-status{
  font-size: 12px !important;
} 

.sorttable  table thead tr th:after {
 position: absolute;
	right: 2px;
}

.sorttable table thead tr th.sort-asc:after {
	content: "";
}

.sorttable table thead tr th.sort-desc:after {
	content: "";
}
/* Switch Styles */

.switch {
  width: 40px;
  height: 20px;
  display: inline-block;
}
.switch input {
  display: none;
}
.switch .slider {
  position: absolute;
  background-color: #ff6562;
  width: inherit;
  height: inherit;
  border-radius: 34px;
  cursor: pointer;
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background-color: #fff;
}
.switch input:checked + .slider {
  background-color: #00c07f;
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
}

.dropdown-menu-right {
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  box-shadow: 0px 0px 0 2000px rgba(0, 0, 0, 0.5);
  // width: max-content !important;
}


