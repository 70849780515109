// smoodi pages custom scss

/* Image upload */
.img-btn {
  box-shadow: 0px 9px 16px 0px rgb(24 28 50 / 25%) !important;
  border-radius: 50%;
  position: absolute;
  background-color: var(--kt-light);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid var(--kt-gray-400);
  transition: all 0.5s ease;
}
.close-btn {
  cursor: pointer;
  height: 24px;
  width: 24px;
  right: -4px;
  top: -4px;
}
.img-box {
  position: relative;
  display: inline-block;
  border-radius: 0.42rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.img-wrapper {
  width: 120px;
  height: 120px;
  border-radius: 0.42rem;
  background-repeat: no-repeat;
  background-size: cover;
  border: 3px solid var(--kt-light);
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%);
}
.box-shadow {
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075) !important;
  border: 3px solid var(--kt-gray-400);
}

/* Toggle switch */
.status-switch {
  position: relative;
  display: inline-block;
  width: 59px;
  height: 26px;
}
.status-switch input {
  display: none;
}
.status-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 6px;
  right: 0;
  bottom: 0;
  background-color: var(--kt-gray-400);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}
.status-slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 3px;
  bottom: 2px;
  background-color: var(--kt-white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .status-slider {
  background-color: var(--kt-primary);
}
input:checked + .status-slider:before {
  transform: translateX(25px);
}
.status-slider:after {
  content: "";
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 65%;
  font-size: 12px;
}
input:checked + .status-slider:after {
  content: "";
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 35%;
  font-size: 12px;
}

// Image view
._11aT5 svg {
  width: 30px !important;
  height: 30px !important;
  color: var(--kt-white) !important;
  opacity: 0.6;
}
._11aT5 svg:hover {
  opacity: 1;
}
._2Yv4j svg {
  margin-left: 0.7em !important;
}
.img-view {
  overflow: hidden;
  width: 300px !important;
  height: 250px !important;
  transition: all 0.5s ease;
}
.img-view:hover {
  opacity: 0.7;
}

.responsive-map-container {
  position: relative;
  padding-bottom: 70%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.responsive-map-container iframe,
.responsive-map-container object,
.responsive-map-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.apexcharts-text,
.apexcharts-title-text,
.apexcharts-legend-text {
  color: var(--kt-dark) !important;
}
#apexchartss400ih5s .apexcharts-text title {
  color: var(--kt-dark) !important;
  fill: none !important;
}
.apexcharts-text apexcharts-yaxis-label title {
  color: var(--kt-dark) !important;
}
.apexcharts-menu .apexcharts-menu-item {
  color: var(--kt-dark) !important;
}
.apexcharts-menu {
  background-color: var(--kt-gray-200) !important;
}

#context-menu {
  position: fixed;
  z-index: 10000;
  width: 200px;
  border-radius: 5px;
}

#context-menu .item {
  padding: 8px 10px;
  font-size: 15px;
  cursor: pointer;
  border-radius: inherit;
}

.sortableBtn {
  cursor: pointer;
}

.price {
  padding: 10px !important;
}
.flavor {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;
}
.table tr:first-child,
.table th:first-child,
.table td:first-child {
  padding-left: 10px !important;
}
.table tr:last-child,
.table th:last-child,
.table td:last-child {
  padding-right: 10px !important;
}
@media only screen and (max-width: 768px) {
  .price tr:first-child,
  .price th:first-child,
  .price td:first-child {
    padding-left: 0px !important;
  }
  .price tr:last-child,
  .price th:last-child,
  .price td:last-child {
    padding-right: 0px !important;
  }

  .price,
  .price tbody,
  .price tr,
  .price td {
    display: revert !important;
    
  }
  .price tr td:first-child {
    background-color: #f5f8fa !important;
    padding-left: 8px !important;
    font-weight: 100 !important;
    border-top: none !important;
  }
}

.react-tooltip {
  z-index: 99999;
}