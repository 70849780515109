// smoodi-form layout changes

// form-control
.form-control {
  font-size: 1.2rem !important;
  &:focus {
    border-color: var(--kt-primary-active) !important;
    //box-shadow: var(--kt-input-focus-box-shadow);
  }
}
.form-control[readonly] {
  color: var(--kt-input-color) !important;
}

.name {
  text-transform: lowercase !important;
}
.text-transform {
  text-transform: none !important;
}

// accordion
.accordion-button:not(.collapsed) {
  color: var(--kt-form-label-color) !important;
}
.ticket-accordion {
  .accordion-button:not(.collapsed) {
    color: var(--kt-primary) !important;
  }
  .accordion-button:not(.collapsed) {
    background-color: transparent !important;
  }
  .accordion-button:not(.collapsed) {
    box-shadow: none !important;
  }
  .accordion-body {
    border-top: 1px solid var(--kt-input-border-color);
  }
}
/* react-select dropdown */
.react-select {
  display: block;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--kt-form-check-label-color);
  background-clip: padding-box;
  appearance: none;
  border-radius: 0.475rem;
  background-color: var(--kt-input-bg) !important;
  min-height: 42px !important;
  border: 1px solid var(--kt-input-border-color);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.my-react-select__control {
  min-height: 42px !important;
  border: 1px solid var(--kt-input-border-color);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.my-react-select__control--is-focused {
  border: 1px solid var(--kt-primary-active) !important;
  border-color: var(--kt-primary-active) !important;
}
.css-t3ipsp-control {
  background-color: var(--kt-input-bg) !important;
  border-color: var(--kt-primary-active) !important;
  border: 1px solid var(--kt-primary-active) !important;
  min-height: 42px !important;
  box-shadow: none !important;
}
.css-1xc3v61-indicatorContainer {
  color: var(--kt-form-check-label-color);
  margin-right: 5px !important;
  padding: 0 !important;
}
.my-react-select__indicator {
  color: var(--kt-form-check-label-color);
  margin-right: 5px !important;
  padding: 0 !important;
}
.css-13cymwt-control {
  background-color: var(--kt-input-bg) !important;
  border: none !important;
  min-height: 43px !important;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
.css-15lsz6c-indicatorContainer {
  color: var(--kt-form-check-label-color) !important;
}
.css-1dimb5e-singleValue {
  color: var(--kt-input-color) !important;
}
.my-react-select__option {
  background-color: var(--kt-card-bg) !important;
}
.my-react-select__option--is-selected {
  background-color: var(--kt-form-check-input-bg-solid) !important;
  color: var(--kt-dark) !important;
}
.my-react-select__option--is-focused {
  background-color: var(--kt-primary-light) !important;
  color: var(--kt-dark) !important;
}
.css-qbdosj-Input {
  color: var(--kt-form-check-label-color) !important;
}
.css-1cfo1cf {
  color: var(--kt-form-check-label-color) !important;
}
.css-166bipr-Input {
  color: var(--kt-form-check-label-color) !important;
}
.css-1jqq78o-placeholder {
  color: var(--kt-input-color) !important;
}
.my-react-select__multi-value__label {
  color: var(--kt-input-color) !important;
}
.css-1p3m7a8-multiValue {
  background-color: var(--kt-primary-light) !important;
}
.my-react-select__multi-value__remove:hover {
  background-color: var(--kt-form-check-input-bg-solid) !important;
  color: var(--kt-dark) !important;
}
.was-validated .react-select:invalid,
.react-select.is-invalid {
  border-color: var(--kt-danger);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3875rem) center;
  background-size: calc(0.75em + 0.775rem) calc(0.75em + 0.775rem);
}
.was-validated .my-react-select__control:invalid:focus,
.my-react-select__control.is-invalid:focus {
  border-color: var(--kt-primary-active) !important;
  box-shadow: none !important;
}

// date picker
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: var(--kt-primary) !important;
}
.rdtPicker td.rdtToday:before {
  border-bottom: 7px solid var(--kt-primary) !important;
}
.rdtPicker {
  background-color: var(--kt-body-bg) !important;
}
.rdtPicker {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}
.rdtPicker .rdtTimeToggle:hover,
.rdtSwitch:hover,
.rdtNext:hover,
.rdtPrev:hover,
.rdtPicker td.rdtDay:hover,
.rdtCounter .rdtBtn:hover,
td.rdtMonth:hover,
td.rdtYear:hover {
  background-color: var(--kt-gray-300) !important;
}

// Date picker invalid
.date-control.is-invalid {
  .form-control {
    border-color: var(--kt-danger);
  }
}
.date-control.is-invalid:focus {
  .form-control {
    border-color: var(--kt-primary-active) !important;
    box-shadow: none !important;
  }
}

// Schedule Tickets
.search {
  font-size: 1rem !important;
  &::placeholder {
    font-size: 1rem !important;
  }
}
.fc-license-message {
  display: none !important;
}
// .fc-timeline-event {
//   background-color: var(--kt-success-light) !important;
//   border: 1px solid var(--kt-success-light) !important;
//   border-left: 3px solid var(--kt-primary) !important;

//   .fc-event-main {
//     color: var(--kt-primary) !important;
//   }
// }
.fc .fc-toolbar {
  display: block !important;
  margin-top: 1em !important;
  text-align: start !important;
  padding: 10px;
  background-color: var(--kt-success-light) !important;
  .fc-toolbar-title {
    color: var(--kt-primary) !important;
  }
}
.react-calendar {
  border: none !important;
  padding: 6px;
  font-family: $font-family-sans-serif !important;
}
.react-calendar__tile--active {
  background: var(--kt-primary) !important;
  color: var(--kt-white) !important;
}
.react-calendar__tile--hasActive {
  background: var(--kt-primary) !important;
  color: var(--kt-white) !important;
}
.react-calendar__tile--now {
  background: var(--kt-primary-active) !important;
  color: var(--kt-white) !important;
}
.react-calendar__month-view__weekdays__weekday {
  abbr[title] {
    text-decoration: none !important;
  }
}
.react-calendar {
  background: var(--kt-body-bg) !important;
}
.react-calendar button {
  color: var(--kt-text-gray-600);
}
.react-calendar__navigation button:enabled:hover {
  background-color: var(--kt-primary-light) !important;
  color: var(--kt-text-gray-600) !important;
}
.react-calendar__tile:enabled:hover {
  background-color: var(--kt-primary-light) !important;
  color: var(--kt-text-gray-600) !important;
}
.react-calendar__navigation button:disabled {
  background-color: var(--kt-primary-light) !important;
}
.max-h-600px {
  max-height: 600px;
}
.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bootstrapMenu {
  .dropdown-menu {
    padding: 0 !important;
    li {
      padding: 10px;
    }
  }
}
.close-ticket-btn {
  position: absolute;
  cursor: pointer;
  height: 24px;
  width: 24px;
  right: -12px;
  top: -2px;
}
.square {
  width: 12px;
  display: inline-flex;
  height: 12px;
}
.square-light-primary {
  background-color: #dfffd5;
}
.square-light-danger {
  background-color: #fff5f8;
}
.square-light-saffron {
  background-color: #ffe7cf;
}
.text-saffron {
  color: #ff671f !important;
}
.square-light-warning {
  background-color: #ffffe6;
}
.text-warning {
  color: #9b870c;
}

// view Status
.form-Status {
  width: 745px;
}

//select column width
.column {
  width: 70%;
}