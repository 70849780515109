// layout changes

.bg-smoodi {
  background-image: url("../../../../images/bg.webp"),
    linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  background-blend-mode: overlay;
}
.bg-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  background-blend-mode: overlay;
}

// footer
.app-footer {
  bottom: 0;
  left: 0;
  position: static;
  right: 0;
  z-index: 0;
}
.app-main-content {
  min-height: calc(100vh - 10rem) !important;
}

//svg
.svg-icon.svg-icon-2 svg {
  height: 1.8rem !important;
  width: 1.8rem !important;
}
.svg-icon.svg-icon-4 svg {
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.scrolltop {
  z-index: 10001 !important;
}

.svg-icon-count svg {
  height: 3.15rem;
  width: 3.15rem;
}

// Tabs link
.nav-line-tabs .nav-item .nav-link.active,
.nav-line-tabs .nav-item.show .nav-link,
.nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {
  border-bottom-width: 2px !important;
}
.nav-line-tabs .nav-item .nav-link.active {
  color: var(--kt-primary) !important;
}

.nav-line-tabs .nav-item .nav-link {
  border-bottom-width: 2px !important;
}

// cursor pointer
.cursor-pointer{
  cursor: pointer;
}