.wo-body {
    background-color: #c6ccf2;
    padding: 100px 0;
  }
  
  .wo-header-container {
    background-color: #faefe6;
    padding: 40px;
    width: 100%;
    max-width: 700px;
    box-sizing: border-box;
  }
  
  .wo-header-title {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .wo-form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
  }
  
  .wo-form-container {
    background-color: white;
    padding: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 700px;
    box-sizing: border-box;
  }
  
  .wo-logo-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .wo-logo {
    width: 100px;
  }
  
  @media (max-width: 480px) {
    .wo-header-container {
      padding: 20px;
    }
  
    .wo-header-title {
      font-size: 1.8rem;
    }
  
    .wo-form-container {
      padding: 20px;
    }
  
    .wo-logo {
      width: 80px;
    }
  }
  
  .wo-form-group {
    margin-bottom: 15px;
  }
  
  .wo-label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
    font-weight: bold;
  }
  
  .wo-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #dbdce4;
    background-color: #ffffff;
  }
  
  .wo-select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #dbdce4;
    background-color: #ffffff;
  }
  
  .wo-textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #dbdce4;
    background-color: #ffffff;
  }
  
  .wo-input-error {
    border-color: red;
  }
  
  .wo-error-message {
    color: red;
    font-size: 12px;
  }
  
  .wo-form-section {
    margin-bottom: 20px;
  }
  
  .wo-submit-btn {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .wo-thead th:nth-child(2),
  .wo-thead th:nth-child(3),
  .wo-thead th:nth-child(4) {
    background-color: #e5eaf4;
    color: black;
    font-weight: bold;
  }
  
  .wo-tbody td:first-child {
    background-color: #e5eaf4;
    font-weight: bold;
  }
  
  .wo-th {
    text-align: center;
  }
  
  .wo-table {
    border: 1px solid rgb(219, 220, 228);
  }
  
  .wo-table>.wo-thead {
    vertical-align: bottom;
    border: 1px solid rgb(219, 220, 228)
  }
  
  .wo-table>.wo-tbody>tr {
    vertical-align: bottom;
    border: 1px solid rgb(219, 220, 228)
  }
  
  
  @media (min-width: 320px) and (max-width: 375px) {
  
    .wo-table tr:first-child,
    .wo-table th:first-child,
    .wo-table td:first-child {
      padding-left: 0px !important;
    }
  
    .wo-table tr:last-child,
    .wo-table th:last-child,
    .wo-table td:last-child {
      padding-right: 0px !important;
    }
  
    .wo-table {
      display: block;
      width: 100%;
      overflow-x: auto;
    }
  
    .wo-tbody {
      display: block;
      width: 100%;
      overflow-x: auto;
    }
  
    .wo-thead {
      display: none;
    }
  
    .wo-tbody td {
      display: block;
      width: 100%;
      text-align: left;
      padding: 12px;
      position: relative;
    }
  
    .wo-tbody td:before {
      content: attr(data-label);
      font-weight: bold;
      width: 45%;
      display: inline-block;
      padding-right: 10px;
    }
  
    .center-radio {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -14px;
    }
  
    .wo-table td {
      padding-left: 4px;
      padding-right: 8px;
      padding-bottom: 5px;
      padding-top: 5px;
    }
  
    .wo-table>.wo-tbody>tr {
      display: block;
    }
  }
  
  .upload-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .upload-box {
    border: 2px dashed #a4a7b1;
    padding: 20px;
    text-align: center;
    background-color: #fbfcff;
    position: relative;
  }
  
  .upload-box:hover {
    border-color: #999;
  }
  
  .file-input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .file-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    color: #555;
  }
  
  .cloud-icon {
    font-size: 2rem !important;
    color: #888;
  }
  
  .file-label span {
    font-size: 1.1rem;
    font-weight: 600;
  }
  
  .file-label p {
    font-size: 0.9rem;
    color: #aaa;
  }
  
  .signature-box {
    border: 1px solid rgb(165 191 226);
    border-radius: 2px;
    background-color: #f9f9f9;
  }
  
  .powered-by {
    margin-top: 10px;
    font-size: 14px;
    color: #666;
  }
  
  .powered-by a {
    color: #39B54A;
    text-decoration: none;
  }
  
  .powered-by a:hover {
    text-decoration: underline;
  }
  
  .center-radio {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .error-box {
    background-color: #fdecee;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
  }
  
  .error-input {
    border-color: #dc3545 !important;
  }
  
  .wo-error {
    background-color: red;
    color: white;
    border-radius: 5px;
    padding-right: 5px;
  }
  
  .error-icon {
    color: white !important;
  }
  
  .error-banner {
    background-color: #dc2625;
    color: white;
    padding: 15px;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
  
  .error-banner button {
    background: white;
    border: none;
    padding: 5px 10px;
    margin-left: 20px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .error-badge {
    background-color: white;
    color: black;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-left: 2px;
    margin-right: 2px;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .submit-button {
    background-color: #28a745;
    color: white;
    padding: 10px 55px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
  }
  
  .submit-button:hover {
    background-color: #218838;
  }
  
  @media (min-width: 320px) and (max-width: 427px) {
    .col-6 {
      width: 100%;
    }
  
    .col-3 {
      width: 50%;
    }
    .lastName {
      margin-top:  5px;
    }
    .preview-media {
      max-height: 160px !important;
  }
  .date {
    padding: 0px;
    }
  }
  
  .close-btn {
    border: none;
  }
  
  .wo-thead th:first-child {
    border-top: 1px solid white !important;
    border-left: 1px solid white !important;
  }
  
  .my-react-select__placeholder {
    color: #a8abbe !important;
  }

  .media-box {
    width: 100%;
    max-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #f0f0f0; /* Background color for empty spaces */
    border-radius: 8px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor to indicate clickability */
  }

  .media-box-custom {
    width: 100%;
    max-height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #f0f0f0; /* Background color for empty spaces */
    border-radius: 8px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor to indicate clickability */
  }


  .media-content {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Cover the container while keeping the aspect ratio */
    border-radius: 8px;
  }

  .preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f3f4f670;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050; 
  }

  .preview-content {
    position: relative;
    display: flex;
    background: #fff;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 10px;
    max-width: 500px;
    max-height: 500px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); 
 
  }

  .preview-media {
    max-width: 480px;
    max-height: 480px;
    width: auto;
    height: auto; 
    border-radius: 8px;
    object-fit: contain;
  }

  .preview-close-btn {
    position: absolute;
    top: -7px; 
    right: -7px;
    background: none; 
    border: none;
    font-size: 30px;
    color: #333; 
    cursor: pointer;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; 
  }

  .signature-wo-error {
    background-color: red;
    color: white;
    border-radius: 5px;
    padding-right: 5px;
    max-width: 51%;
  }